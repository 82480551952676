/** @jsx jsx */

import {graphql} from 'gatsby'
import {jsx, Styled} from 'theme-ui'
import * as dayjs from 'dayjs'
import {Layout, SliceZone, SEO} from '../components'
import {Container} from '../elements'
import Navigation from '../components/navigation'

require('dayjs/locale/de')

dayjs.locale('de') // use locale

const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)

const Post = ({data: {prismicEvents, i18nPathname}, location}) => {
  const {data, lang} = prismicEvents

  return (
    <Layout>
      <Navigation lang={lang} i18nPathname={i18nPathname.nodes} />
      <SEO
        node={prismicEvents}
        seoTitle={data.seo_title || data.cb_title}
        seoDescription={data.seo_description || data.cb_desc}
        seoImage={data.seo_image.url || data.image.url}
        pathname={location.pathname}
        article
      />
      <div>
        <div
          sx={{
            backgroundColor: 'primary',
            p: ['7rem 1.5rem 5rem 1.5rem', '', '12rem 1.5rem 8rem 1.5rem'],
            textAlign: 'center',
          }}
        >
          <div
            sx={{
              maxWidth: (t) => t.layout.article,
              m: '0 auto',
            }}
          >
            <Styled.h1
              sx={{
                color: (t) => t.colors.gray[1],
                m: '0 0 1rem 0',
                textAlign: 'center',
              }}
            >
              {data.cb_title}
            </Styled.h1>
            <span
              sx={{
                color: '#fde3ce',
                fontSize: '1.25rem',
                textAlign: 'center',
              }}
            >
              {dayjs().to(dayjs(data.event_date).format('DD/MM/YYYY'))}
            </span>
          </div>
        </div>
      </div>
      <Container type="article">
        <SliceZone allSlices={data.body1} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query EventBySlug($uid: String!) {
    i18nPathname: allPrismicEvents(filter: {uid: {eq: $uid}}) {
      nodes {
        uid
        lang
      }
    }

    prismicEvents(uid: {eq: $uid}) {
      first_publication_date(formatString: "DD.MM.YYYY")
      last_publication_date(formatString: "DD.MM.YYYY")
      uid
      lang
      data {
        cb_title
        cb_desc
        image {
          url
        }
        event_date(formatString: "DD/MM/YYYY", locale: "de")
        body1 {
          ... on PrismicEventsBody1Text {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicEventsBody1Image {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicEventsBody1Quote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
        }

        seo_title
        seo_description
        seo_image {
          url
          localFile {
            childImageSharp {
              resize {
                height
                src
                width
              }
            }
          }
        }
      }
    }
    events: allPrismicEvents(
      limit: 2
      sort: {fields: data___event_date, order: DESC}
      filter: {uid: {ne: $uid}}
    ) {
      nodes {
        first_publication_date(formatString: "DD.MM.YYYY")
        data {
          cb_title
          cb_desc
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Post
